@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --back-color: rgb(0, 0, 0);
  /* --main-color: rgb(242, 210, 171); */
  --main-color: rgb(211, 142, 9);
  --red-color: rgb(156, 61, 84);
  --darkred-color: rgb(183, 52, 49);
  /* --darkred-color: rgb(119, 67, 96); */
  --transp-color: rgb(255, 255, 255, 0);
  --text-color: #cac7c7;
}

body {
  font-family: "Jost", sans-serif;
  font-weight: 400px;
}

/* ============================================Navbar================================================= */

nav.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

#bootstrap-override nav.navbar {
  background-color: var(--back-color) !important;
}

nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.06rem;
  cursor: pointer;
  border-top: 0.1875rem solid var(--transp-color);
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav li a {
  color: var(--main-color);
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

#bootstrap-override nav a img {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem !important;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(231,171,121, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav > li > a:active {
  border-top: 0.1875rem solid var(--red-color);
  color: var(--main-color);
}

/* ========================================Header================================================== */

.header-wraper {
  /* position: relative; */
  background: url(back2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.main-info h1 {
  color: var(--red-color);
  margin: 0.625rem;
  /* margin-top: 10rem; */
  letter-spacing: 0.3rem;
}

/* ==================typed text==================== */
.typed-text {
  font-size: 2rem;
  color: var(--main-color);
}

/* ==============================================About Me============================================== */

.about {
  background: url(back2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.about-text p {
  font-size: 1.2em;
  line-height: 1.8;
  text-align: justify;
  padding: 10px;
  color: var(--text-color);
  background-color: var(--back-color);
  box-shadow: 0 10px 10px rgba(6, 3, 3, 0.5);
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
}

.highlight {
  color: var(--main-color);
  font-size: 1.2em;
}
@media (max-width: 768px) {
  .about .about-text p {
    font-size: 1 rem;
    line-height: 1.4;
    max-height: 400px;
  }
  .about .highlight {
    font-size: 1 rem;
  }
}

/* ================================================projects============================================= */

.projects {
  background: url(back2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: 150vh;
  padding-top: 100px;
}

.projects .card {
  background-color: var(--back-color);
  border: 2px solid var(--red-color);
  border-radius: 15px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 1);
  transition: transform 0.2s;
}

.projects .cards-group .card:hover {
  transform: scale(1.05);
}

.projects .card .card-body .card-title {
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--main-color);
  text-align: center;
}

.projects .card-text {
  font-size: 1rem;
  color: var(--text-color);
  text-align: justify;
}

.projects .btn-primary {
  background-color: var(--red-color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.projects .btn-primary:hover {
  background-color: var(--back-color);
}

.projects .text-project-alert {
  color: var(--text-color);
}

.projects .text-project-finance {
  color: var(--text-color);
}

.projects .text-project-remind {
  color: var(--text-color);
}

/* ==================================================Slide============================================ */

.slide-videos {
  min-height: 100vh;
  padding-top: 100px;
  background: url(back2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ===================================================Skills=========================================== */
.skills {
  min-height: 100vh;
  padding-top: 100px;
  background: url(back2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.accordion-body {
  background-color: var(--back-color);
}

.accordion-body .card-body {
  background-color: var(--back-color);
}

.accordion-body .card-body .card-title {
  color: var(--main-color);
}

.accordion-body .card-body .card-text {
  color: var(--text-color);
}

.item1 .card img {
  width: 100%;
}

.item2 .card img {
  width: 100%;
}

#bootstrap-override,
.accordion-button {
  background-color: var(--red-color) !important;
  color: var(--text-color) !important;
  font-size: 1.2rem;
  font-weight: 600;
}

#bootstrap-override,
.accordion-button:hover {
  background-color: var(--back-color) !important;
}

.carousel-fixed-size {
  width: 100%;
  width: 600px;
  height: 600px;
  overflow: hidden;
}

.carousel-fixed-size img {
  width: 100%;
  object-fit: cover;
}

/* ==================================================Footer============================================= */

.footer {
  background-color: var(--back-color);
  color: var(--main-color);
  display: flex;
  align-items: center;
  max-height: 100%;
}
